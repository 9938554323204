import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import QuoteHero from '../components/Quote/QuoteHero/QuoteHero'
import QuoteContent from '../components/Quote/QuoteContent/QuoteContent'

class Quote extends React.Component {
  render() {
    const postNode = {
      title: `Quote - ${config.siteTitle}`,
    }

    return (
      <Layout>
        <Helmet>
          <title>{`Quote - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath="quote" customTitle />
        <QuoteHero />
        <QuoteContent />
      </Layout>
    )
  }
}
export default Quote
