import React from 'react'
import { Link } from 'gatsby'
import './QuoteContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import { FaPhone, FaEnvelope, FaCheckCircle } from 'react-icons/fa'
const axios = require('axios')

export class QuoteContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  showThankYou() {
    this.setState({ hideForm: true })
  }

  submitForm(ev) {}

  onchangeText(val) {
    var OriginalValue = document.getElementById('name').value
    document.getElementById('first_name').value = OriginalValue.substr(
      0,
      OriginalValue.indexOf(' ')
    )
    document.getElementById('last_name').value = OriginalValue.substr(
      OriginalValue.indexOf(' ') + 1
    )
  }

  renderFormSuccess() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-wrap">
          <div className="thanks-text">
            <div>
              Thanks for the message! We'll be in touch within the next day or
              so.
            </div>
            <div className="thanks-icon">
              <FaCheckCircle />
            </div>
          </div>
        </div>
      )
    } else {
    }
    return el
  }

  renderForm() {
    let el
    el = (
      <form
        onSubmit={this.submitForm.bind(this)}
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
        id="QUOTE REQUEST FORM"
        noValidate
      >
        <input type="hidden" name="oid" value="00D4T000000FZaP" />
        <input type="hidden" name="retURL" value="http://rackner.com?thankyou=quote" />
        <input
          type="hidden"
          id="first_name"
          maxLength="40"
          name="first_name"
          size="20"
        />
        <input
          type="hidden"
          id="last_name"
          maxLength="80"
          name="last_name"
          size="20"
        />
        <div className="_form-content">
          <div className="_form_element _x02636122 _full_width ">
            <div className="_field-wrapper">
              <input
                type="text"
                onChange={this.onchangeText}
                name="name"
                id="name"
                placeholder="Full Name"
              />
            </div>
          </div>
          <div className="_form_element _x32424883 _full_width ">
            <div className="_field-wrapper">
              <input
                type="text"
                name="email"
                placeholder="Email Address"
                required
              />
            </div>
          </div>
          <div className="_form_element _x84396670 _full_width ">
            <div className="_field-wrapper">
              <input type="text" name="phone" placeholder="Phone Number" />
            </div>
          </div>
          <div className="_form_element _field3 _full_width ">
            <div className="_field-wrapper select">
              <select name="00N4T000004EGRg" id="00N4T000004EGRg" required>
                <option className="default" value="">
                  Project Type
                </option>
                <option value="Cloud Architecture/DevOps">
                  Cloud Architecture/DevOps
                </option>
                <option value="Cloud Migration/Audit">
                  Cloud Migration/Audit
                </option>
                <option value="Website/Web Application">
                  Website/Web Application
                </option>
                <option value="Mobile Application">IoT/Mobile Application</option>
                <option value="Data Analytics">Data Analytics/AI Solution</option>
              </select>
            </div>
          </div>
          <div className="_form_element _field4 _full_width ">
            <div className="_field-wrapper select">
              <select name="00N4T000004EGRl" id="00N4T000004EGRl" required>
                <option className="default" value="">
                  Budget
                </option>
                <option value="Less than $10,000">Less than $10,000</option>
                <option value="$10,000 - $25,000">$10,000 - $25,000</option>
                <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                <option value="$100,000 - $200,000">$100,000 - $200,000</option>
                <option value="More than $200,000">More than $200,000</option>
              </select>
            </div>
          </div>
          <div className="_form_element _field2 _full_width ">
            <div className="_field-wrapper">
              <textarea
                name="description"
                autoComplete="nope"
                placeholder="Description"
              />
            </div>
          </div>
          <div className="_button-wrapper _full_width">
            <button
              id="_form_2_submit"
              className="btn btn-default form-button"
              type="submit"
            >
              Submit
            </button>
          </div>
          <div className="_clear-element" />
        </div>
        <div
          className="_form-thank-you"
          style={{
            display: 'none',
          }}
        />
      </form>
    )
    return el
  }

  render() {
    return (
      <div className="quote-content">
        <div className="quote-card hide-mobile">
          {this.renderForm()}
          {this.renderFormSuccess()}
        </div>
        <div className="split light subheader-block">
          <div className="text-wrap">
            <div className="inner">
            Send us a message and we'll be in touch within 1-2 business days{' '}
              <br />
              <Link to="/contact">
                <button className="btn-raised-light-blue-inverse">
                  No project? Just contact us
                </button>
              </Link>
            </div>
          </div>
          <div className="quote-card hide-desktop">
            {this.renderForm()}
            {this.renderFormSuccess()}
          </div>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-sm-12 ">
              <div
                className="location-card"
                style={{ backgroundImage: 'url("/dcarch.jpg")' }}
              >
                <div className="overlay" />
                <div className="text">
                  <div className="title">DC Metro Area</div>
                  <div className="phone">
                    <FaEnvelope /> contact@rackner.com
                  </div>
                  <div className="description">
                    7338 Baltimore Ave <br />
                    Suite 102 <br />
                    College Park, Maryland 20740
                  </div>
                </div>
              </div>
              <div
                className="location-card"
                style={{ backgroundImage: 'url("/dcarch.jpg")' }}
              >
                <div className="overlay" />
                <div className="text">
                  <div className="title">College Park, MD</div>
                  <div className="phone">
                    <FaEnvelope /> contact@rackner.com
                  </div>
                  <div className="description">
                  7338 Baltimore Avenue, Suite 102
                  <br></br>
                  College Park, Maryland 20740
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default QuoteContent
