import React from 'react'
import './QuoteHero.scss'

export class QuoteHero extends React.Component {
  render() {
    return (
      <div
        className="quote-hero"
        style={{ backgroundImage: 'url(/cloudsecopsbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>Get a Quote</h1>
          </div>
        </div>
      </div>
    )
  }
}

export default QuoteHero
